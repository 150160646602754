import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRequest } from "../../../services/api";

//fetch list of warehouse

export const fetchWarehouse = createAsyncThunk(
  "warehousev2/fetchWarehouse",
  async () => {
    const response = await getRequest("/warehouse-management");
    return response.data;
  }
);

const warehousev2Slice = createSlice({
  name: "warehousev2",
  initialState: {
    error: null,
    status: "idle",
    warehouse: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWarehouse.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchWarehouse.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.warehouse = Array.isArray(action.payload.data)
          ? action.payload.data
          : [];
      })
      .addCase(fetchWarehouse.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setPage, setPageSize } = warehousev2Slice.actions;

export default warehousev2Slice.reducer;
