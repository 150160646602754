import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Button, Spin } from "antd";
import "./index.css";
import { fetchWarehouse } from "../../redux/Slice/Warehousev2/warehousev2Slice";

const WarehouseLayout = () => {
  const dispatch = useDispatch();
  const { warehouse, status, error } = useSelector(
    (state) => state.warehousev2
  );

  useEffect(() => {
    dispatch(fetchWarehouse());
  }, [dispatch]);

  if (status === "loading") {
    return <Spin size="large" />;
  }

  if (status === "failed") {
    return <p>Error: {error}</p>;
  }

  if (!Array.isArray(warehouse)) {
    console.error("Warehouse data is not an array: ", warehouse);
    return <p>Error: Warehouse data is not an array</p>;
  }

  return (
    <div className="warehouse-layout">
      <div className="controls">
        <Button type="primary">Control 1</Button>
        <Button type="primary">Control 2</Button>
        <Button type="primary">Control 3</Button>
      </div>
      <div className="warehouse-content">
        {warehouse.map((row) => (
          <div className="row-container" key={row.code}>
            <h3>{row.code}</h3>
            <div className="floor-container">
              {row.responseFloorBasicModels.map((floor) => (
                <div key={floor.code} className="floor">
                  <Card className="floor-card">
                    {floor.responseChamberBasicModels
                      .slice(0, 25)
                      .map((chamber) => (
                        <Card.Grid
                          key={chamber.id}
                          style={{ backgroundColor: chamber.color }}
                          className="chamber-grid"
                        >
                          {chamber.code}
                        </Card.Grid>
                      ))}
                  </Card>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WarehouseLayout;
