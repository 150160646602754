// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warehouse-layout {
  padding: 20px;
}

.controls {
  margin-bottom: 20px;
}

.warehouse-content {
  overflow-x: auto;
  white-space: nowrap;
}

.row-container {
  margin-bottom: 20px;
}

.floor-container {
  display: flex;
  flex-direction: column;
}

.floor-card {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  overflow-x: auto;
}

.chamber-grid {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(100% / 25 - 10px);
  text-align: center;
  padding: 8px;
  box-sizing: border-box;
  min-width: 100px;
  max-width: 100px;
  border-radius: 10% !important;
  margin-right: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/qlkho2/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,QAAQ;EACR,gBAAgB;AAClB;;AAEA;EACE,YAAgC;EAAhC,cAAgC;EAAhC,kCAAgC;EAChC,kBAAkB;EAClB,YAAY;EACZ,sBAAsB;EACtB,gBAAgB;EAChB,gBAAgB;EAChB,6BAA6B;EAC7B,iBAAiB;AACnB","sourcesContent":[".warehouse-layout {\n  padding: 20px;\n}\n\n.controls {\n  margin-bottom: 20px;\n}\n\n.warehouse-content {\n  overflow-x: auto;\n  white-space: nowrap;\n}\n\n.row-container {\n  margin-bottom: 20px;\n}\n\n.floor-container {\n  display: flex;\n  flex-direction: column;\n}\n\n.floor-card {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 8px;\n  overflow-x: auto;\n}\n\n.chamber-grid {\n  flex: 0 1 calc(100% / 25 - 10px);\n  text-align: center;\n  padding: 8px;\n  box-sizing: border-box;\n  min-width: 100px;\n  max-width: 100px;\n  border-radius: 10% !important;\n  margin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
